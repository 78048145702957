<template>
  <div class="app-container">
    <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="日期" prop="payTimeArr">
        <el-date-picker
            v-model="payTimeArr"
            clearable
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="用户状态" prop="status">
        <el-select
            v-model="queryParams.status"
            size="mini"
            placeholder="用户状态"
            clearable
        >
          <el-option value="" label="全部"></el-option>
          <el-option v-for="status in statusOptions" :value="status.value"
                     :label="status.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学习顾问身份" prop="adviser" label-width="100">
        <el-select
            v-model="queryParams.adviser"
            placeholder="学习顾问身份"
            clearableo
            size="mini"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="学习顾问" value="1"></el-option>
          <el-option label="非学习顾问" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>

    </el-form>
    <div
        style="display: flex;flex-direction: row;justify-content: flex-end;align-items: center;margin-bottom: 10px;padding-right: 20px">
      <span style="color: #606266;font-size: 14px;margin:0 10px">隐藏空数据:</span>
      <el-switch
          @change="handleQuery"
          v-model="queryParams.invalid"
          :active-value="true"
          :inactive-value="0">
      </el-switch>
      <span style="color: #606266;font-size: 14px;margin:0 10px">切换统计方式:</span>
      <el-select size="mini" v-model="switchType">
        <el-option :value="0" label="平均统计"></el-option>
        <el-option :value="1" label="总数合计"></el-option>
        <el-option :value="2" label="实操人数"></el-option>
      </el-select>
    </div>

    <el-table
        v-loading="loading"
        element-loading-text="拼命加载中...日志查询时间较长 ┓(;´_｀)┏ 请稍等... (*▼ｰ(｡-_-｡)"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        height="750"
        :stripe="true"
        :expand-row-keys="['1-null']"
        :border="true"
        highlight-current-row
        :row-key="getRowKeys"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">

      <el-table-column
          prop="deptName"
          label="部门"
          :highlight-current-row="true"
          width="210"
          align="left">
      </el-table-column>
      <el-table-column
          prop="userName"
          label="姓名"
          :highlight-current-row="true"
          align="center"/>

        <el-table-column
                prop="operateCountNum"
                label="点击操作数"
                width="200"
                align="center">
            <template v-slot="scope">
          <span v-html="filterTableDataByType('operateCountNum', 'operatePeople', scope)"
                :style="{ color: changeColor(scope.row.operateCountNum) }">
          </span>
            </template>
            <template #header>
                <el-tooltip effect="dark" content="功能键点击；机会查询，机会查看，双呼，回库，机会校验，机会跟进，机会领取，订单补录，全部机会指派，添加机会；唤醒数据；导入批量回库；机会校验_操作哦记录等功能板块。" placement="top">
                    <span>点击操作数<i class="el-icon-warning"/></span>
                </el-tooltip>
            </template>
        </el-table-column>

      <el-table-column
          prop="operateNum"
          label="操作总次数"
          width="200"
          align="center">
        <template v-slot="scope">
          <span v-html="filterTableDataByType('operateNum', 'operatePeople', scope)"
                :style="{ color: changeColor(scope.row.operateNum) }">
          </span>
        </template>
        <template #header>
          <el-tooltip effect="dark" content="拨打+查看" placement="top">
            <span>操作次数<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
          prop="receiveNum"
          label="领取次数"
          align="center">
        <template v-slot="scope">
                <span v-html="filterTableDataByType('receiveNum', 'receivePeople', scope)"
                      :style="{ color: changeColor(scope.row.receiveNum) }">
                </span>
        </template>
        <template #header>
          <el-tooltip effect="dark" content="主动领取+分配获取" placement="top">
            <span>领取次数<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
          prop="viewNum"
          label="查看次数"
          align="center">
        <template v-slot="scope">
          <span v-html="filterTableDataByType('viewNum', 'viewPeople', scope)"
                :style="{ color: changeColor(scope.row.viewNum) }">
          </span>
        </template>
        <template #header>
          <el-tooltip effect="dark" content="查看机会详情信息次数" placement="top">
            <span>查看次数<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
          prop="queryTelephoneNum"
          label="查看手机号"
          align="center">
        <template v-slot="scope">
                                <span v-html="filterTableDataByType('queryTelephoneNum', 'queryPeople', scope)"
                                      :style="{ color: changeColor(scope.row.queryTelephoneNum) }">
                                </span>
        </template>
        <template #header>
          <el-tooltip effect="dark" content="列表页复制手机号+详情页复制手机号+领取列表查看手机号" placement="top">
            <span>查看手机号<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
          prop="callNum"
          label="拨打次数"
          align="center">
        <template v-slot="scope">
                                <span v-html="filterTableDataByType('callNum', 'callPeople', scope)"
                                      :style="{ color: changeColor(scope.row.callNum) }">
                                </span>
        </template>
      </el-table-column>

      <el-table-column
          prop="backStoreNum"
          label="回库次数"
          align="center">
        <template v-slot="scope">
          <span v-html="filterTableDataByType('backStoreNum', 'backPeople', scope)"
                :style="{ color: changeColor(scope.row.backStoreNum) }">
          </span>
        </template>
        <template #header>
          <el-tooltip effect="dark" content="主动回库" placement="top">
            <span>回库次数<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
          prop="checkNum"
          label="校验次数"
          align="center">
        <template v-slot="scope">
                                <span v-html="filterTableDataByType('checkNum', 'checkPeople', scope)"
                                      :style="{ color: changeColor(scope.row.checkNum) }">
                                </span>
        </template>
        <template #header>
          <el-tooltip effect="dark" content="学习顾问页面点击机会校验功能" placement="top">
            <span>校验次数<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column
          prop="amountSum"
          label="流水总额"
          align="center">
        <template v-slot="scope">
          <span :style="{ color: changeColor(scope.row.amountSum) }" v-if="switchType === 2">
            {{ filterTableDataByType('amountSum', 'amountPeople', scope) }}
          </span>
          <span :style="{ color: changeColor(scope.row.amountSum) }" v-else>
            {{ (filterTableDataByType('amountSum', 'amountPeople', scope) / 100).toFixed(2) }}
          </span>
          <!--                                <span :style="{ color: changeColor(scope.row.amountSum) }">-->
          <!--                                    合计:{{ (scope.row.amountSum / 100).toFixed(2) }}-->
          <!--                                  <br>-->
          <!--                                  平均: {{-->
          <!--                                    scope.row.amountPeople === 0 ? 0 : (scope.row.amountSum / scope.row.amountPeople / 100).toFixed(2)-->
          <!--                                  }}-->
          <!--                                </span>-->
        </template>
        <template #header>
          <el-tooltip effect="dark" content="线上订单+补录订单" placement="top">
            <span>流水总额<i class="el-icon-warning"/></span>
          </el-tooltip>
        </template>
      </el-table-column>

    </el-table>


  </div>
</template>

<script>
import * as deptApi from "@/api/system/dept";
import * as operateCountApi from "@/api/crm/operateCount"

export default {
    name: "OperateCount",
    data() {
        return {
            switchType: 0,
            averageNum: 0,
            realNum: 0,
            allNum: 0,
            // 状态数据字典
            statusOptions: [
                {value: 1, label: "正常"},
                {value: 2, label: "离职"},
                {value: 0, label: "暂停"}
            ],
            deptList: [],
            adviserList: [],
            queryParams: {},
            searchRules: {},
            payTimeArr: [],
            tableData: [],
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        end.setTime(end.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            loading: false,
        }
    },
    created() {
        // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
        this.getDeptList();
        this.init();
    },
    computed: {
        changeColor() {
            return (num) => {
                if (num === 0) {
                    return '#cccccc';
                } else if (num > 0 && num <= 10) {
                    return '#3399FF';
                } else if (num > 10 && num <= 50) {
                    return '#0000FF';
                } else return '#cc3300';
            }
        },
        // 根据选中的筛选项, 过滤数据
        filterTableDataByType() {
            return (params1, params2, scope) => {

                if (scope.row.accountId) {
                    return `${scope.row[params1]}`
                }

                // return `
                // <span>总数:${scope.row[params1]}<br>平均数:${(scope.row[params1] === 0 || scope.row[params2] === 0 ? scope.row[params1] : (scope.row[params1] / scope.row[params2]).toFixed(1))}<br>实操人数:${scope.row[params2]} </span>`
                switch (this.switchType) {
                    case 1:
                        return scope.row[params1]
                    case 2:
                        return scope.row[params2]
                    default:
                        return scope.row[params1] === 0 || scope.row[params2] === 0 ? scope.row[params1] : (scope.row[params1] / scope.row[params2]).toFixed(1)

                }
            }
        }
    },
    methods: {
        getRowKeys(row) {
            return row.deptId + '-' + row.accountId
        },
        keyboard(e) {
            if (e.keyCode === 13) {
                this.handleQuery()
            }
        },
        /**
         * 日期格式化工具
         * @param date
         * @returns {string}
         */
        dateFormat(date) {
            const y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? '0' + m : m;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            return y + '-' + m + '-' + d;
        },
        /**
         * 页面初始化数据加载
         */
        init() {
            // this.queryParams.deptId = 1;
            let today = this.dateFormat(new Date())
            this.payTimeArr = [today, today]
            this.getData()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.$refs['queryForm'].validate((valid) => {
                if (valid) {
                    this.getData()
                }
            });

        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.payTimeArr = [];
            this.handleQuery();
        },
        /**
         * 获取数据
         */
        getData() {
            this.loading = true;
            // this.$set("queryParams", "deptId", deptId)
            if (this.payTimeArr && this.payTimeArr.length > 0) {
                this.queryParams['startDate'] = this.payTimeArr[0]
                this.queryParams['endDate'] = this.payTimeArr[1]
                // this.$set("queryParams", "startDate", this.payTimeArr[0])
                // this.$set("queryParams", "endDate", this.payTimeArr[1])
            }
            this.tableData = []
            operateCountApi.getData(this.queryParams).then(resp => {
                    if (resp.success) {
                        this.tableData = resp.data
                        this.loading = false
                    }
                }
            )
        },
        /** 查询部门列表 */
        getDeptList() {
            deptApi.listDept().then(response => {
                this.deptList = response.data;
            });
        },
    },
    updated() {
        if (this.$refs.dataTab && this.tableData.length > 0) {
            this.$refs.dataTab.toggleRowExpansion(this.tableData[0], true);
        }
    },
}
</script>

<style scoped>
/deep/ .el-table__body tr.current-row > td {
    background-color: #B3D8FF !important;
    color: #ffffff !important;
}
</style>
