import request from '@/utils/request.js';

const url = "/manage/system/operate-count";

/**
 * 获取数据
 * @returns {*}
 * @param params
 */
export function getData(params) {
    return request({
        url: `${url}/getData`,
        method: 'GET',
        params: params
    })
}